<template>
  <div>
    <h1 class="text-h4">Управление пользовтелями</h1>
    <v-divider class="my-2"></v-divider>
    <v-card class="mt-2">
      <v-card-title>
        Новый пользователь
      </v-card-title>
      <v-card-text class="d-sm-flex align-start text-body-2">
        <div class="d-flex flex-column mr-2 align-center">
          <v-avatar size="90">
            <v-img src="/images/avatars/avatar1.svg"></v-img>
          </v-avatar>
          <br/>
          <div class="justify-center">
            <v-btn small @click="uploadAvatar">Сменить аватар</v-btn>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-form ref="form" v-model="form.formValidity" @submit.prevent="submitUserCreateForm">
            <v-text-field
              v-model="form.data.username"
              label="Логин"
              :rules="form.rules.username"
            ></v-text-field>
            <v-text-field
              v-model="form.data.email"
              label="Email"
              :rules="form.rules.email"
            ></v-text-field>
            <v-text-field
              v-model="form.data.fullName"
              label="Полное имя"
              :rules="form.rules.fullName"
            ></v-text-field>
            <v-combobox
              v-model="form.data.status"
              label="Статус пользователя"
              hint="Укажите необходимый статус пользователя"
              persistent-hint
              chips
              :items="statusOptions"
              :rules="form.rules.status"
            ></v-combobox>
            <v-combobox
              v-model="form.data.role"
              label="Роли пользователя"
              hint="Укажите необходимые роли пользователя"
              persistent-hint
              chips
              clearable
              :items="roleOptions"
              :rules="form.rules.role"
            ></v-combobox>
            <div class="mt-6">
              <v-btn color="primary" :disabled="!form.formValidity" type="submit">Сохранить</v-btn>
              <v-btn class="ml-1" :to="{name:'users.list'}">Назад</v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleEmail, ruleNotEmptyCollection, ruleRequired } from '@/utils/FormRules'
import ToastService from '@/service/ToastService'
import UserCreateForm from '@/model/user/UserCreateForm'
import { mapActions } from 'vuex'

export default {
  ...dashboardPageCommonAttributes,
  name: 'UserCreatePage',
  data: function () {
    return {
      busy: false,
      panelMetadata: [0],
      form: {
        formValidity: false,
        data: {
          email: '',
          username: '',
          fullName: '',
          status: { text: 'В ожидании', value: 'Pending' },
          role: [{ text: 'Пользователь', value: 'User' }]
        },
        rules: {
          email: [ruleRequired(), ruleEmail()],
          username: [ruleRequired()],
          fullName: [ruleRequired()],
          status: [ruleRequired()],
          role: [ruleRequired()]
        }
      },
      statusOptions: [],
      roleOptions: []
    }
  },
  created() {
    const statusOptions = [
      { text: 'Активный', value: 'Active' },
      { text: 'Заблокирован', value: 'Blocked' },
      { text: 'В ожидании', value: 'Pending' }
    ]

    this.statusOptions = statusOptions

    const roleOptions = [
      { text: 'Пользователь', value: 'User' },
      { text: 'Флорист', value: 'Florist' },
      { text: 'Менеджер', value: 'Manager' },
      { text: 'Админ', value: 'Admin' }
    ]

    this.roleOptions = roleOptions
  },
  methods: {
    ...mapActions('user',['createUser']),
    uploadAvatar() {
      ToastService.notImplementedYet()
    },
    submitUserCreateForm() {
      this.busy = true

      const userCreateForm = new UserCreateForm({
        email: this.form.data.email,
        username: this.form.data.username,
        fullName: this.form.data.fullName,
        status: this.form.data.status.value,
        role: this.form.data.role.value
      })

      this.createUser({ form: userCreateForm })
        .then(() => {
          ToastService.success('Данные сохранены')
        })
        .then(() => {
          this.$router.push({ name: 'users.list' })
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.busy = false
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Новый пользователь'
      }
    }
  }
}
</script>

<style scoped>

</style>
